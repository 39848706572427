@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*Font Roboto*/
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500;900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;800&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@800&display=swap'); */
:root {
    --darkblue: #10275b;
    --red: #e10000;
    --black: #333;
  }
  
body{
    background-color:#fcfcff;
    /* font-family: 'Montserrat', sans-serif; */
    font-family: 'Poppins', sans-serif;
    color:#10275b;
    color:var(--darkblue);
    /* font-family: 'Roboto', sans-serif; */
    /* font-family: 'Open Sans', sans-serif; */
}
/* width */
::-webkit-scrollbar {
    width: 7px;
}
.print-media{
	padding-top: 62px;
}
/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px grey; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #10275b;
    background: var(--darkblue); 
    
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #44609c; 
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    line-height: 1.2em;
    margin-bottom: revert;
}
.btn {
    padding: 5px 25px;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    color: #fff !important;
    background-color: #e10000;
    background-color: var(--red);
    border: none;
  }
  
  .btn:hover {
    background-color: #e10000;
    background-color: var(--red);
  }
  
  .btn:active {
    background-color: #e10000;
    background-color: var(--red);
  }
  @media only screen and (max-width: 500px) {
    .btn{
      padding: 14px 25px;
      width: 100%;
    }
  }
/* CSS */
.btn-1 {
    font-size: 16px;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
    color: #333 !important;
    color: var(--black) !important;
    cursor: pointer;
    border: 3px solid;
    padding: 0.25em 0.5em;
    box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
    position: relative;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  .btn-1:hover {
    color: #333 !important;
    color: var(--black) !important;
  }
  .btn-1:active {
    box-shadow: 0px 0px 0px 0px;
    top: 5px;
    left: 5px;
    color: #333 !important;
    color: var(--black) !important;
  }
  
  @media (min-width: 768px) {
    .btn-1 {
      padding: 0.25em 0.75em;
    }
  }

/* CSS */
.btn-2 {
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 13px 20px 13px;
  outline: 0;
  border: 1px solid #333;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btn-2:after {
  content: "";
  background-color: #ffe54c;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}

.btn-2:hover:after {
  top: 0px;
  left: 0px;
}

@media (min-width: 768px) {
  .btn-2 {
    padding: 13px 50px 13px;
  }
}

.btn-3 {
    padding: 5px 25px;
    border-radius: 5px;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    color: #fff !important;
    background-color: #10275b;
    background-color: var(--darkblue);
    border: 3px solid #10275b;
    border: 3px solid var(--darkblue);
}
.btn-3:hover {
    color: #10275b !important;
    color: var(--darkblue) !important;
    background-color: #fff;
}
p{
    margin-bottom: 1.5rem;
    color: #282636;
}
abbr[data-bs-original-title], abbr[title]{
    cursor: pointer;
}
code.main {
    color: #d63384;
    font-size: 120%;
    display: flex;
    background: #f1f1f1;
    border-left: 2px solid #d6d6d6;
    line-height: 1.4;
    padding: 1em;
}
.highlight-text{
    background-color: #b7ff07; 
box-shadow: 0px 0px 0px 2px #b7ff07;
/* text-shadow: -0.09em 0 0 #F22613,  0.09em 0 0 #00FFFF; */
}
img{    
    animation: fadein 1s;
    -moz-animation: fadein 1s; /* Firefox */
    -webkit-animation: fadein 1s; /* Safari and Chrome */
    -o-animation: fadein 1s; /* Opera */
}
figcaption{
    font-size: 12px;
}
@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1140px;
    }
}
.bg-light {
    /* background-color: #ffffff!important; */
    background-color:#fcfcff !important;
}
.bg-white {
    background-color:#fff !important;
}

.sub-header{
    position: fixed;
    width: 100%;
    top: 7.4%;
    z-index: 20;
    background-color: #fdfdfd;
    border-bottom: 1px solid #f6f6f6;
}
.ms-header__title {
    flex: 1 1 100%;
    width: 100%; 
  }
.text-gradient{
    background: -webkit-linear-gradient(#120058, #0049a7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
  
  .ms-slider {
    display: inline-block;
    height: 1.6em;
    overflow: hidden;
    vertical-align: middle;
    -webkit-mask-image: linear-gradient(transparent, white, white, white, transparent);
            mask-image: linear-gradient(transparent, white, white, white, transparent);
    mask-type: luminance;
    mask-mode: alpha;
  }
  .ms-slider__words {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    -webkit-animation-name: wordSlider;
            animation-name: wordSlider;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: 7s;
            animation-duration: 7s;
  }
  .ms-slider__word {
    display: block;
    line-height: 1.3em;
    text-align: left;
  }
  
  @-webkit-keyframes wordSlider {
    0%, 27% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }
    33%, 60% {
      -webkit-transform: translateY(-25%);
              transform: translateY(-25%);
    }
    66%, 93% {
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
    }
    100% {
      -webkit-transform: translateY(-75%);
              transform: translateY(-75%);
    }
  }
  
  @keyframes wordSlider {
    0%, 27% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
    }
    33%, 60% {
      -webkit-transform: translateY(-25%);
              transform: translateY(-25%);
    }
    66%, 93% {
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
    }
    100% {
      -webkit-transform: translateY(-75%);
              transform: translateY(-75%);
    }
  }
  
   /* animation intro-anime */
.intro-anime {
	-webkit-animation: intro-anime 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: intro-anime 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
} 
@-webkit-keyframes intro-anime {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes intro-anime {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
/*animation fade-in-left*/
.fade-in-left {
	-webkit-animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-left 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

/*animation tilt-in-fwd-tr*/
.swing-in-left-fwd {
	-webkit-animation: swing-in-left-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-left-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

/*animation swing-in-left-fwd*/
@-webkit-keyframes swing-in-left-fwd {
  0% {
    -webkit-transform: rotateY(100deg);
            transform: rotateY(100deg);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 1;
  }
}
@keyframes swing-in-left-fwd {
  0% {
    -webkit-transform: rotateY(100deg);
            transform: rotateY(100deg);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 1;
  }
}

.header .container {
    padding: 0;
}
.navbar-toggler {
    border-color: transparent !important;
}

#basic-navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
}
.navbar-brand .logo{
    max-width: 146px;
}
.logo-sub{
    padding-left:1em;
    margin-left:1em;
    border-left: 1px solid #d6d6d6;
}
.navbar-nav{
    margin-left: auto;
}
.navbar-nav a{
    color: #10275b !important;
    color: var(--darkblue) !important;
    text-decoration: none;
    transition: 1ms;
}


.navbar-nav a:hover{
    color: #e10000 !important;
    color: var(--red) !important;
}
.navbar-nav a.active{
    color: #e10000 !important;
    color: var(--red) !important;
}
.dropdown-item.active, .dropdown-item:active {
    text-decoration: none;
    background-color: transparent;
}
/* .dropdown-item .nav-link {
    padding: 0 !important;   
} */
/* .dropdown-item a:hover{
    border-bottom: none !important;
    background-color: transparent;
}
.dropdown-item:hover{
    border-bottom: none !important;
    background-color: transparent;
} */
/* 
.dropdown-item:hover{   
    padding-left: inherit !important;
    padding-right: inherit !important;
    margin-right: inherit !important;
    margin-left: inherit !important;
} */

.jumbo-img{
    /* background-image: url(../images/bg-1.jpg); */
    background-position: center;
    background-size: cover;
    color: #fff;
}
.shadow-box{
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 14px 2px rgba(0,0,0,0.1);
}
.text-center{
    text-align: center;
}
.content-center{
    width: 100%;
    display: flex;
    justify-content: center;

}
.page-header{
    padding: 2.5em 0;
    /* margin-bottom: 1.5em; */
    /* background: #f6f6f6; */
}
.page-header h2{
    font-size: 3.2em;
    font-weight: 800;
    line-height: 3.5rem;
    margin-bottom: 8px;
}
@media only screen and (max-width: 500px){
    .page-header h2{
        font-size: 2.2em;
        line-height: 1.2em;
    }
}
.page-header p{
    font-size: 21px;
    margin-bottom: 0;
}
section{
    margin-bottom: 26px;
    padding: 12px 0;
    border-bottom: 1px solid #e3e3e3;
}
section:last-child, section:first-child{
    border-bottom: none; 
}
section p{
    margin-bottom: 0.3rem;
    margin-bottom: 1.2rem;
}
section h2{
    font-size: 18px;
    margin-top: 1em;
    margin-bottom: 0.3em;
}
h2.sub-title{
    font-size: 18px;
    margin-bottom: 1.5em;
    text-transform: uppercase;
    font-weight: 800;
    color: #e10000;
    color: var(--red);
}
section a{
    color: #e10000;
    color: var(--red);
}
section h3{
    font-size: 18px !important;
    margin-top: 1em;
    margin-bottom: 0.3em;
    font-weight: bold;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
}
.featured-item{
    transition: 100ms;
}
.featured-item h3{
    margin-top: 0;
}
.featured-item img{
    width: 140px;
    height: 92px;
    border-radius: 6px;
    object-fit: cover;
    margin-right: 12px;
}
@media only screen and (max-width: 500px){
    .featured-item img{
        width: 100%;
        height: 180px;
        margin-bottom: 1em;
    } 
}
.featured-item:nth-of-type(n + 6) {
    display: none !important;
}
.featured-item a{
    text-decoration: none;
}
/* .featured-list .featured-item {
    margin-bottom: 2em;
} */
.featured-list .featured-item {
    margin-bottom: 2em;
    padding-top: 1.5em;
    border-top: 1px solid #eaeaea;
}
.featured-list .featured-item:nth-of-type(n + 5){
   border-bottom: none; 
}
.featured-list .featured-item h3 a{
    color: inherit;
    font-size: 20px !important;
    margin-bottom: 26px;
}
/* .featured-item:hover{
    -webkit-box-shadow: 0px 0px 0px 10px rgb(247 247 247);
    -moz-box-shadow: 0px 0px 0px 10px rgb(247, 247, 247);
    box-shadow: 0px 0px 0px 14px rgb(247 247 247);
    background: #f7f7f7;
    cursor: pointer;
    border-radius: 2px;
} */
.featured-item:hover{
    cursor: pointer;
}
.featured-item:hover a{
    color: #e10000 !important;
    color: var(--red) !important;
}
/* section.main-banner{
    padding: 23px;
} */
.main-banner{
  display: inline-block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 354px;
}

.banner-img{
    border-radius: 0 50px 0 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

/* .main-banner .banner-img{
    width: auto;
} */
@media only screen and (max-width: 500px){
    .main-banner{
        display: flex;
        flex-direction: column-reverse;
        height: auto;
      }
      .banner-img{
        position: inherit;
    }
    .main-banner .banner-img{
        width: 100%;
    }
}
.side-box{
    border-radius: 12px;
    display: inline-block;
}
.side-box h3{
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
}
.side-box .side-box-cont-img{
    width: 36%;
    float: right;
}
.side-box.downloads p{
line-height: normal;
}
.side-box svg{
    float: left;
}
.side-box.navinna-24 img{
    width: 100%;
}
.row.spacer{
    padding: 1.5rem 0px;
}
.title-box h1{
    margin-bottom: 12px;
    font-size: 3.8rem;
    font-weight: 800;
}
/* .title-box h1:before {
    content: "";
    display: block;
    width: 300px;
    height: 300px;
    position: absolute;
    background: #fff3f3;
    z-index: -1;
    border-radius: 50%;
} */
.title-box .main-banner p{
    font-size: 21px;
    margin-bottom: 2em;
    /* font-weight: 200; */
}
/* .title-box p{
    padding: 0 16px;
} */
.title-box button{
    margin: 0 16px;
}
@media only screen and (max-width: 500px){
    .title-box h1{
        font-size: 2.8rem;
    }
    .title-box h1:before {
        display: none;
    }
    }
.hide-me{
    display: none;
}
.home .profile-box {
    padding: 0 0 1em;
}
.home .profile-box .profile-img{
    width: 21%;
    float: left;
    margin-right: 12px;
    border-radius: 50%;
}
.home .profile-box p{
font-size: 14px;
}
.sticky-scroll{
    position: -webkit-sticky;
    position: sticky;
    height: 100%;
    top: 12%;
}
.about .profile-img{
    width: 20%;
}
.about img{
    width: 100%;
}
.about-saminda .profile-img{
    width: 100%;
}
.about-saminda img{
    width: 100%;
}
.about-saminda .section img{
    background-color: #FFD500;
    border-radius: 8px;
}
.about-saminda h3, h3{
    font-size: 1.5em !important;
    margin-bottom: 0.3em;
    font-weight: bold;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
}
.about-saminda .section h5{
    margin-top: 1em;
    margin-bottom: 1em;
}


@media only screen and (max-width:500px){
    .navbar-brand{
    padding-left: 12px;
    }
    .about .profile-img{
        width: 30%;
        margin-bottom: 12px;
    }
    .about-saminda .profile-img{
        width: 100%;
        margin-bottom: 12px;
    }
    .sticky-scroll{
        position: inherit;
        height: inherit;
        top: inherit;
    }
}
.works.webuse_consult img{
    width: 100%;
}
.square-ad {
    border-radius: 12px;
    padding: 2em;
    box-shadow: 0px 0px 14px 2px rgb(0 0 0 / 10%);
}
.circle-ad {
    margin: 0 auto;
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
    width: 340px;
    height: 340px;
    background: #ffd400;
    /* background: #edff58; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    padding: 2em;
    font-size: 15px;
box-shadow: 4px 4px 5px 0px rgba(0,0,0,0.15);
}
.circle-ad h4{
    line-height: 2rem;
    font-size: 2rem;
}
.circle-ad .text-left{
    text-align: left;
}
.circle-ad h4:after {
    width: 240px;
    content: "";
    display: block;
    background: white;
    height: 1px;
    margin: 0 auto;
    margin-top: 8px;
}
.circle-ad a{
    font-size: 14px;
    color: #333;
}
.home .circle-ad {
    -webkit-transform: scale(0.9) rotate(11deg);
            transform: scale(0.9) rotate(11deg);
    background: #f1f1f1;
}
/* .works .tab-pane .circle-ad p{
    margin-bottom: 12px;
} */
.works .nav-link, .works .card a{
    color: #e10000;
    color: var(--red);
    text-decoration: none;
}
.works .card h3 a{
    color: #10275b !important;
    color: var(--darkblue) !important;
}
.works .card h3{
    font-size: 1.5em !important;
margin-bottom: 0.3em;
font-weight: bold;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
}
.post-grid-item{
    margin-bottom: 2em;
}
.post-grid-item .card-img-top { 
    box-shadow: 0 0 14px 2px rgb(0 0 0 / 10%);
    height: 173px;
    border-radius: 8px;
    object-fit: cover;
}
.works .post-grid-item .card-img-top {  
    box-shadow: 0 0 14px 2px rgb(0 0 0 / 10%);
    border-radius: 8px;
    height: 260px;
    object-fit: cover;
}
.post-grid-item .card{
    background-color: transparent;
    border: none;
    border-radius: 0;
}
.post-grid-item .card .card-body{
    padding: 1em 0;
}
.post-grid-item .card .card-body p{
    margin-bottom: 0;
    font-size: 18px;
}
.post-grid-item .card .card-footer {
    background-color: transparent;
    padding: 1em 0;
}
.post-grid-item .card-deck .card {
    margin: 0 !important;
    margin-bottom: 24px !important;
}
.blog .card a{
        color: #e10000 !important;
        color: var(--red) !important;
        text-decoration: none;
}
.blog .card h3{
    font-size: 1.5em !important;
margin-bottom: 0.3em;
font-weight: bold;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
}
.blog .card h3 a{
    color: #10275b !important;
    color: var(--darkblue) !important;
}
.post-grid-item .card .card-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;    
}
/*Blog List View*/
.blog .list-view {
    display: flex;
    flex-direction: column;
}

.blog .list-view .post-grid-item {
    margin-bottom: 3em;
    width: 100%;
}

.blog .list-view .post-grid-item .card {
    display: flex;
    flex-direction: row;
}
.blog .list-view .post-grid-item .card-img-top {
    width: 262px;
    height: 158px;
}
.blog .list-view .post-grid-item .card .card-body {
    padding: 0 1em;
}
.blog .list-view h3 {
    font-size: 1.5em !important;
}
.blog .list-view p {
    font-size: 21px;
}
@media only screen and (max-width:900px){
    .blog .list-view .post-grid-item {
        width: 100%;
    }
}
@media only screen and (max-width:500px){
    .blog .list-view .post-grid-item {
        width: 100%;
    }
    .blog .list-view .post-grid-item .card {
        display: inherit;
        flex-direction: inherit;
    }
    .blog .list-view .post-grid-item .card-img-top {
        width: 100%;
        height: 173px;
    }
    .blog .list-view .post-grid-item .card .card-body {
        padding: 1em 0;
    }
}
/*List View End*/
.date{
    width: 9.3ch;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 6px;
}
.single-post{
    display: flex;
    flex-direction: column;
}
.single-post h3{
    margin-bottom: 12px;
}
.single-post ol li{
    margin-bottom: 1em;
}
.single-post p{
    line-height: 1.8em;
}
.single-post a{
    color: #e10000 !important;
    color: var(--red) !important;
}
.single-post-cont h1{
    font-weight: 800;
}
.single-post-cont img{
    max-width: 100%;
}
.single-post-cont .wp-block-image{
    margin-bottom: 2em;
}
.contact h3{
    line-height: 1.5em;
}
.contact h5{
    margin-bottom: 0.5em;
}
.contact h3 .email{
    font-weight: 800;
    color: #e10000;
    color: var(--red);
}
.contact a{
    margin-right: 1.5em;
    color: inherit;
}
figcaption{
    margin: 20px 0;
}
.single-post .exerpt p{
        /* color: var(--red) */
        /* font-weight: 200; */
        font-size: 21px;
        line-height: inherit;
        /* font-style: italic; */
        margin-bottom: 1em;
}
.single-post .post-footer{
    border-top: 1px solid #dedede;
}
.breadcrumbs{
    background-color: inherit;
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    grid-gap: 0;
    gap: 0;
}
.breadcrumbs li:last-child {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
.breadcrumbs svg{
    margin: 0 6px !important;
}
.breadcrumbs a{
    color: #e10000;
    color: var(--red);
    display: flex;
    align-items: center;
}
.page-not-found h2{
    font-size: 11em;
    color: #dedede;
}
/*Page transition*/
.main-container, .page-cont {
    min-height: 76vh;
    padding-bottom: 2.4rem;
    animation: fadein 0.2s;
    -moz-animation: fadein 0.2s; /* Firefox */
    -webkit-animation: fadein 0.2s; /* Safari and Chrome */
    -o-animation: fadein 0.2s; /* Opera */
}
@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}

/* .footer{
    background: #d6d6d6 !important;
} */
.mars-plaque{
    background: #f5f5f5;
    padding: 8px;
    border-radius: 25px;
    justify-content: center;
    align-items: inherit;
    padding: 7px 12px;
}

.mars-plaque p{
    font-size: 11px;
    margin-bottom: 0;
}

/*ScrollToTop CSS Start*/
.top-to-btm{
    position: relative;
  }
  .icon-position{
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 20;
  }
  .icon-style{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #10275b;
    background-color: var(--darkblue);
    border: 2px solid #fff;
    border-radius: 50%;
    height: 38px;
    width: 38px;
    color: #fff;
    cursor: pointer;
    -webkit-animation: movebtn 3s ease-in-out;
            animation: movebtn 3s ease-in-out;
    transition: all .5s ease-in-out;
  }
  .icon-style:hover{
    -webkit-animation: none;
            animation: none;
    background: #fff;
    color: #10275b;
    color: var(--darkblue);
    border: 2px solid #10275b;
    border: 2px solid var(--darkblue);
  }
  @-webkit-keyframes movebtn {
    0%{
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
    25%{
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
    }
    50%{
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
    75%{
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
    100%{
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  }
  @keyframes movebtn {
    0%{
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
    25%{
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
    }
    50%{
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
    75%{
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
    100%{
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  }
  /*ScrollToTop CSS End*/

  .scroll-view{
    position: relative;
  }
  .scroll-view.btn-position a{
    background-color: #10275b;
    background-color: var(--darkblue);
    border-radius: 4px;
    color: #fff !important;
    transition: 2ms;
    padding-left: 1em !important;
    padding-right: 1em !important;
  }


.footer{
    padding: 18px;
}
.footer .social-links {
    margin-bottom: 12px;
}
.footer .social-links a{
    margin-right: 12px;
    color: #333;
    color: var(--black);
}
.footer .social-links a:hover{
    color: #646464;
}

/*Nav*/
.navbar .megamenu{ padding: 1rem; }
.navbar-nav {
    grid-gap: 1em;
    gap: 1em;
}
.col-megamenu img {
    width: 100%;
}
.col-megamenu .nav-link {
    padding-left: 0 !important;
}
.col-megamenu .title{
    font-weight: 800;
}
.megamenu .image-box{
    border-left: 1px solid #dedede;
    padding: 0 2em;
}
@media only screen and (max-width:500px){
    .megamenu .image-box{
        border-left: inherit;
        padding: inherit;
    } 
}
.dropdown-menu.megamenu.show{
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 9px 14px -3px rgb(0 0 0 / 10%);
    border: none;
}
/* ============ desktop view ============ */
@media all and (min-width: 992px) {
	
	.navbar .has-megamenu{position:static!important;}
	.navbar .megamenu{left:0; right:0; width:100%; margin-top:2px;  }
	
}	
/* ============ desktop view .end// ============ */


/* ============ mobile view ============ */
@media(max-width: 991px){
	.navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
		overflow-y: auto;
	    max-height: 90vh;
	    margin-top:10px;
	}
}
/* ============ mobile view .end// ============ */
